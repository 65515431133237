.comp-terms {
  .comp-legal-doc {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-bottom: 50px;

    .card {
      display: flex;
      flex-direction: column;
      flex: 1;

      .contents {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-inline-end: 15px;
        margin-inline-start: 15px;
        margin-block-start: 30px;

        .footer {
          margin-top: 50px;

          .top-button {
            width: 41px;
            float: right;
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            cursor: pointer;
            align-items: center;
          }

          .back-button {
            float: left;
          }
        }
      }
    }
  }
}