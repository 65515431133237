.App {
  .offline-overlay {
    background: $overlayBackgroundColor;
    opacity: 0.9;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    .button.button-big {
      margin: 2em auto;
    }
    .offline-message {
      color: white;
      font-size: 1.5rem;
      width: 90%;
    }
  }
}
