.App {
  .transaction-code-with-loader {
    display: flex;
    justify-content: center;

    .tc-loader {
      position: relative;
      display: flex;
      align-items: center;
      max-width: 360px;
      margin: 30px auto 0;

      .loader-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
      }

      .loader {
        margin: 0;
        right: -35px;
        pointer-events: none;
      }
    }
  }

  .react-code-input {
    margin: 5px;

    input {
      font-family: $inputFontFamily !important;
      width: 30px !important;
      max-width: 37px !important;
      height: 55px !important;
      background-color: #f2f3f4 !important;
      margin: 2px !important;
      padding: 5px !important;
      opacity: 1;
    }

    input::selection {
      background: none !important;
    }

    input:focus {
      outline: none !important;
      caret-color: transparent !important;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 1' width='30' height='1'%3E%3Cstyle%3E tspan %7B white-space:pre %7D .shp0 %7B fill: %23000000 %7D %3C/style%3E%3Cpath id='svg_5' class='shp0' d='M1.25 0L28.75 0C29.44 0 30 0.11 30 0.25L30 0.75C30 0.89 29.44 1 28.75 1L1.25 1C0.56 1 0 0.89 0 0.75L0 0.25C0 0.11 0.56 0 1.25 0Z' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 18px;
      background-position-y: 45px;
      background-position-x: center;
    }

    input[value=""] {
      background-color: #e6e9ec !important;
    }

    input[data-valid="false"] {
      color: #d50024 !important;
      background-color: #f7ccd3 !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .error {
    color: $errorTextColor;
    height: 10px;
    margin-top: 15px;
  }

  .button-big {
    min-width: 200px;
    margin: 75px auto 0;
  }

  .button-big.inactive {
    background: $inactiveButtonBackgroundColor;
    border: $inactiveButtonBorder;
    color: $inactiveButtonTextColor;
  }
  .verify-header {
    margin-bottom: 5%;
  }

  .verify-code-text {
    width: 80%;
    margin: auto;
    margin-top: 10%;
    text-align: center;
  }

  .verify-code-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    width: 200px;
    min-width: 6.9rem;
    margin: auto;
    margin-top: 24%;
    margin-bottom: 2%;
  }

  .make-sure,
  .try-again {
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .comp-verify-code {
    &-start, &-verify {
      height: 100%;
      .card {
        display: flex;
        flex-direction: column;
        height: calc(100% - 75px);
        justify-content: space-between;
      }
    }
  }
}
.App.desktop section .comp-verify-code {
  &-start .card, &-verify .card {
    display: flex;
  }
}

