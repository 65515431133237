.App {
  .comp-doc-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    .id-pic,
    .passport-pic,
    .paper-pic {
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .paper-pic {
      transform: rotate(-180deg);
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-doc-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .id-pic,
    .passport-pic {
      transform: rotate(90deg);
    }
    .paper-pic {
      transform: rotate(-90deg);
    }
    .id-pic,
    .passport-pic,
    .paper-pic {
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      height: calc(82vw - env(safe-area-inset-left) - env(safe-area-inset-right));
      width: calc(72vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-doc-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .id-pic,
    .passport-pic,
    .paper-pic {
      //width: 85%;
      //height: 75%;
      top: auto;
      left: auto;
      //right: auto;
      //bottom: auto;
      height: calc(75% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      //top: calc(10vh + env(safe-area-inset-top));
      width: calc(75% - env(safe-area-inset-left) - env(safe-area-inset-right));
    }
  }
}

.App.desktop {
  .comp-doc-overlay {
    width: 45vw;
    height: calc(45vw / 1.6);
    margin: calc(5vh) auto;
    .id-pic,
    .passport-pic,
    .paper-pic {
      width: 85%;
      height: 84%;
    }
  }
}
