.App {
  .comp-transaction-code-loading {
    // TODO: Will be removed soon
    .loader {
      top: 230px;
    }
  }

  .comp-transaction-code-loading {
    .main-content {
      display: flex;
      margin: auto;
      justify-content: center;
    }
  }
}
