.App {
    .comp-start {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        padding-bottom: 50px;

        .card {
            display: flex;
            flex-direction: column;
            flex: 1;

            .main-content {
                flex: 1 0 auto;
            }
        }

        .button-big {
            width: 140px;
            min-width: auto;
            margin: auto;
        }

        .welcome {
            font-size: 2em;
            margin-top: 30px;
            line-height: 1em;
        }

        .pictograms {
            margin-top: 10vh;
            margin-bottom: 5vh;

            .flow-portrait {
                display: none;
            }

            .pic1 {
                margin: 5px 0;
            }

            .pic3 {
                margin: 10px 0;
            }

            .pic-arrow {
                margin: 20px 10px;
            }
        }
    }
}

.App.portrait-primary,
.App.portrait-secondary {
    .comp-start {
        .pictograms {
            .flow-portrait {
                display: inline-block;
            }

            .flow-landscape {
                display: none;
            }
        }
    }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
    .comp-start {
        .welcome {
            margin-top: 1vh;
        }

        .pictograms {
            margin-top: 4vh;

            .flow-portrait {
                display: none;
            }
        }
    }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
    .comp-start {
        .welcome {
            margin-top: -3vh;
        }
    }
}

.App.desktop {
    .comp-start {
        .card {
            display: flex;
        }
    }
}

//iPhone 5
@media (max-width: 374px) {
    .App.portrait-primary {
        .comp-start {
            .pictograms {
                .flow-portrait {
                    width: 80%;
                }
            }
        }
    }
}

/* Larger than desktop */
@media (min-width: 1000px) {

    .App.landscape-primary.mobile section,
    .App.landscape-secondary.mobile section {
        .comp-start {
            .welcome {
                margin-top: -3vh;
            }
        }
    }
}