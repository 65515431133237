.App {
  .comp-doc-back-side-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    .additional-doc-pic {
      width: 100px;
      height: 63px;
      margin-bottom: 20px;
    }
    .frame {
      width: 85%;
      height: 87%;
      border-radius: 12px;
      margin: auto;
      .doc-back-side-frame {
        width: 90%;
        height: 90%;
        position: absolute;
        left: 5%;
        top: 5%;
        overflow: visible;
        .a, .b, .c {
          fill: none;
        }
        .c, .e {
          stroke-dasharray: 0.1 15;
        }
        .a, .b {
          stroke-linecap: round;
          stroke-linejoin: round;
          opacity: 1;
        }
        .a, .c {
          stroke: $whiteColor;
          stroke-width: 6px;
        }
        .b, .e {
          stroke: $blackColor;
          stroke-width: 4px;
        }
        .d {
          clip-path: none;
        }
      }
      .spacer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        height: calc(100% - 16px);
        .inner-frame {
          background: rgba($overlayBackgroundColor, $overlayOpacity);
          box-shadow: $buttonBoxShadow;
          border-radius: 5px;
          width: 100%;
          height: 100%;
          display: flex;
          .additional-doc-pic {
            margin: auto;
          }
          .button {
            margin-top: 20px;
            width: 30%;
            display: block;
            min-width: 180px;
          }
          .text {
            color: $whiteColor;
            line-height: 25px;
            font-size: 1.2em;
          }
          div {
            margin: auto;
            width: 90%;
            max-width: 1300px;
            div {
              outline: none;
              opacity: $overlayOpacity;
            }
            .button {
              opacity: $buttonOpacity;
              position: relative;
            }
          }
        }
      }
    }
    .captured-section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      .confirmation-section {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img {
          margin: auto;
        }
      }
      .title {
        width: 90%;
        margin: auto;
        font-size: 1.2em;
      }
      img {
        width: 80%;
        padding: 0.4rem 0;
      }
      .confirmaition-links {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        .button-big {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1em;
          width: auto;
          min-width: 6.9rem;
          margin: 0;
          svg {
            width: 20px;
            height: 20px;
            .a {
              stroke: $whiteColor;
            }
          }
        }
      }
      .loader-section {
        img {
          width: 85%;
        }
        div {
          height: 5vh;
          display: flex;
          margin: auto;
          justify-content: center;
        }
      }
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-doc-back-side-message {
    .frame {
      height: calc(44% * 1.7777);
      width: 80vw;
      margin: auto;
      .spacer {
        padding: 8px;
        height: calc(100% - 16px);
        .inner-frame {
          div {
            width: 80vw;
            div {
              width: 95%;
            }
            svg.additional-doc-pic {
              height: 150px;
              width: 170px;
            }
            div.button {
              width: 30%;
            }
          }
        }
      }
    }
    .captured-section {
      img {
        width: 60%;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-doc-back-side-message .frame {
    width: calc(96vh * 1.7777);
    height: calc(68vh + 0px);
    .captured-section {
      .confirmaition-links {
        width: 60%;
      }
      img {
        width: auto;
        height: 50%;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-doc-back-side-message {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    .frame {
      margin: auto;
      max-width: 85%;
      .e, .c {
        width: 99.5%;
      }
      .doc-back-side-frame {
        height: 80%;
        top: 9%;
      }
    }
  }
}

.App.desktop {
  .comp-doc-back-side-message {
    position: relative;
    width: 45vw;
    height: calc(45vw / 1.6);
    margin: calc(5vh) auto;
    .frame {
      min-width: 350px;
      svg {
        margin-top: 0px;
      }
    }
    .captured-section {
      .confirmation-section {
        height: 90%;
      }
      img,
      .confirmaition-links {
        width: 90%;
      }
      img {
        padding: 1.25rem 0;
      }
    }
    .loader-section {
      img {
        width: 90%;
      }
      div {
        height: 0;
        margin-top: 3vh;
        span {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
}

.App.mobile {
  .comp-doc-back-side-message {
    .frame {
      .e, .c {
        width: 98%;
      }
    }
  }
}

@media (max-width: 374px) {
  .App.portrait-primary {
    .comp-doc-back-side-message {
      .captured-section {
        .confirmation-section {
          height: 85vh;
        }
      }
    }
  }
}

@media (max-height: 620px) {
  .App.portrait-primary,
  .App.portrait-secondary {
    .comp-doc-back-side-message .frame .spacer .inner-frame div svg.additional-doc-pic {
      height: 108px;
      width: 125px;
    }
    .comp-doc-back-side-message .frame {
      .spacer .inner-frame div svg.additional-doc-pic {
        margin: auto;
      }
    }
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .App.landscape-primary section,
  .App.landscape-secondary {
    .comp-doc-back-side-message .frame {
      width: 88%;
      height: 85%;
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .App.landscape-primary section,
  .App.landscape-secondary {
    .comp-doc-back-side-message .frame .spacer {
      width: 97%;
    }
  }
}
