.App {
  .form-address {
    margin-top: 24px;
  }

  .country-line,
  .gender-line,
  .nationality-line {
    .input-container {
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
}
