.App {
  .comp-end {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-bottom: 50px;
    .card {
      display: flex;
      flex-direction: column;
      flex: 1;
      .contents {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .main-content {
        flex: 1 0 auto;
      }
    }
    .contents {
      .congrats {
        font-size: 2em;
        margin: 20px 0 10px;
      }
      .loader-container {
        min-height: 5rem;
        .loader {
          left: calc((100vw - 4rem) / 2);
          height: 4rem;
          width: 4rem;
        }
      }
      .pic {
        width: 40vw;
        height: 40vw;
        margin: 10px;
        .a,
        .b,
        .c {
          fill: $endPageSuccessCheckmarkColor;
        }
      }
      .message {
        font-size: 1.5em;
        margin: 10px 0;
        div {
          width: 90%;
          margin: auto;
        }
      }
      .link {
        font-size: 1.3em;
        color: $linkButtonColor;
        cursor: pointer;
        margin-bottom: 30px;
        span {
          border-bottom: 1px solid $linkButtonColor;
        }
      }
      .button-big {
        min-width: 200px;
      }
      .link {
        margin-bottom: 10px;
      }
      .button-big {
        margin-top: 60px;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-end {
    .contents {
      .congrats {
        font-size: 1.5em;
        margin: 10px 0 5px;
      }
      .message {
        font-size: 1.2em;
        margin: 5px 0;
      }
      .link {
        font-size: 1.2em;
        color: $linkButtonColor;
        cursor: pointer;
        margin-bottom: 15px;
        span {
          border-bottom: 1px solid $linkButtonColor;
        }
      }
      .pic {
        width: 20vh;
        height: 20vh;
      }
    }
  }
}

.App.desktop {
  .comp-end {
    .card {
      display: flex;
    }
  }
}
