.App {
  .comp-utility-message {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 80%;
    z-index: 1;

    &.full {
      height: 95%;
    }

    .frame {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      margin: auto;
      height: 100%;

      .unility-frame {
        width: 90%;
        height: 85%;
        position: absolute;
        left: 5%;
        top: 5%;
        overflow: visible;

        .a,
        .b,
        .c {
          fill: none;
        }

        .a,
        .b {
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 0 15;
          opacity: 1;
        }

        .a,
        .c {
          stroke: $whiteColor;
          stroke-width: 6px;
        }

        .b,
        .e {
          stroke: $blackColor;
          stroke-width: 4px;
        }

        .d {
          clip-path: none;
        }
        &.desktop {
          height: 80%;
        }
      }

      .spacer {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 4%;
        width: 90%;
        padding: 8px;
        height: 85%;

        .inner-frame {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          width: 95%;
          height: 85%;
          overflow: visible;
          background: rgba($overlayBackgroundColor, $overlayOpacity);
          box-shadow: $buttonBoxShadow;
          border-radius: 5px;
          margin: auto;

          .timeouted-action {
            margin-top: 2%;
            font-size: 1em;
          }

          .text {
            color: $whiteColor;
            line-height: 25px;
            font-size: 1.2em;
          }

          div {
            margin: auto;
            width: 90%;
            max-width: 1300px;

            div {
              outline: none;
              opacity: $overlayOpacity;
            }

            svg.pic {

              path.a,
              path.d {
                fill: $whiteColor;
              }
            }

            .button {
              opacity: $buttonOpacity;
              position: relative;
            }
          }

          .overlay-button {
            background: $overlayButtonBackgroundColor;
            color: $overlayButtonTextColor;
            width: 20%;
          }
        }
      }
    }
  }
}
.App.portrait-primary.mobile {
  .comp-utility-message {
    height: 75%;
    .unility-frame {
      height: 100%;
    }
    .spacer {
      height: 100%;
      .inner-frame {
        height: 90%;
        width: 85%;
      }
    }
    &.full {
      height: 87%;
    }
  }
} 