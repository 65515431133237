.App {
  header {
    height: $headerHeight;
    background: $primaryBackgroundColor;
    .logo {
      position: absolute;
      top: 16px;
      left: 16px;
      width: $logoWidth;
    }
    .progress-icons {
      position: absolute;
      top: 36px;
      right: 25px;
    }
  }
  header.logo-center {
    .logo {
      position: inherit;
      margin: 16px auto 0;
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  header {
    display: flex;
    margin-left: 2vw;
  }
}

.App.desktop {
  header {
    width: 50vw;
    min-width: 500px;
    margin: 20px auto;
    .logo {
      position: inherit;
      float: left;
      margin-top: 20px;
    }
    .progress-icons {
      position: relative;
      float: right;
    }
  }
  header.logo-center {
    .logo {
      float: none;
    }
  }
}
