.App {
  .comp-thank-you {
    .heading {
      font-size: 2em;
      margin-top: 15px;
      line-height: 1em;
    }
    .text-caption {
      margin: 15px 20px 0;
      line-height: 1.5em;
      .message {
        margin-top: 20px;
      }
    }
    .block {
      background-color: #f9f9f9;
      margin: 20px;
      padding: 10px 20px 20px;
      box-shadow: $buttonBoxShadow;
      border-radius: 8px;
      .button-big {
        width: 90%;
        margin-top: 20px;
        max-width: 300px;
      }
      .text-caption {
        font-size: .9em;
        margin: 15px 15px 0;
      }
    }
    .button-link {
      color: $linkButtonColor;
      margin: 15px 0;
    }
    .button-big {
      width: auto;
      min-width: 8.75rem;
      white-space: nowrap;
    }
  }
}
