.App {
  .comp-something-went-wrong {
    .card {
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .button-big {
        margin: 0 auto;
        max-width: 10em;
      }
      .message {
        margin: 10vh 10px 0;
        text-align: left;
      }
    }
  }
}

.App.desktop {
  section {
    .comp-something-went-wrong {
      .card {
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .button-big {
          margin: 0 auto;
          max-width: 10em;
          margin-bottom: 10em;
        }
      }
    }
  }
}
