.App {
  .comp-lang-switch {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;

    .lang-switch__menu {
      background-color: $languageSelectorPopupBackgroundColor;
    }

    .lang-switch__control {
      border: none;
      box-shadow: none;
      background-color: transparent;
    }

    .lang-switch__option {
      background-color: transparent;
      color: $inactiveLanguageColor;
    }

    .lang-switch__option--is-selected {
      background-color: transparent;
      color: $activeLanguageColor;
    }

    .lang-switch__single-value {
      color: $activeLanguageColor;
    }

    .link.lang {
      > span {
        text-transform: uppercase;
        padding: 0 4px 0 4px;
        cursor: pointer;
      }
    }
    .link.lang:last-child {
      border-right: 0;
    }
    .link.lang.active {
      color: $activeLanguageColor;
    }
  }
}
