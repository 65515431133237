.App {
  .comp-environment-test {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-bottom: 50px;

    .card {
      display: flex;
      flex-direction: column;
      flex: 1;

      .main-content {
        flex: 1 0 auto;

        justify-content: flex-start;
      }
    }

    .doc-types:last-child {
      border-bottom: 2px solid #bbb;
    }

    h1 {
      width: 90%;
      margin-bottom: 30px;
    }

    .clicable-area {
      min-height: 14vh;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 2px solid #bbb;

      .loader-container {
        height: 20px;
        width: 20px;

        span {
          height: 15px;
          width: 15px;
        }
      }

      img {
        height: 20px;
        margin-left: 10px;
      }

      .clicable-area-item {
        display: flex;
        justify-content: space-between;

        width: 300px;
        padding: 0 10px;

        .doc-types {
          margin: auto;
        }
      }

      .text-caption {
        text-align: left;
      }
    }

    .button-big {
      width: 140px;

      margin-top: 60px;
    }

    .checked {
      background: $buttonBackgroundColor;
    }
  }
}