.App {
  .comp-end-failed {
    padding-bottom: 20px;
    .contents {
      display: flex;
      flex-direction: column;
      .congrats {
        font-size: 2em;
        margin: 20px 0 10px;
      }
      .message {
        font-size: 1.5em;
        margin: 10px 0;
        div {
          width: 90%;
          margin: auto;
        }
      }
      .link {
        font-size: 1.3em;
        color: $linkButtonColor;
        cursor: pointer;
        margin-bottom: 30px;
        span {
          border-bottom: 1px solid $linkButtonColor;
        }
      }
      .next-button {
        margin-top: 20px;
      }
      .button-big {
        min-width: 200px;
      }
    }
  }
}
