.App {
  .comp-id-document-scan-frame {
    position: absolute;
    top: env(safe-area-inset-top);
    left: env(safe-area-inset-left);
    width: calc(100% - env(safe-area-inset-left) - env(safe-area-inset-right));
    height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    display: flex;
    .pic {
      width: 100px;
      height: 63px;
      margin-bottom: 20px;
    }
    .pic-paper-permit {
      .a, .b {
        fill: $whiteColor;
      }
      .b:nth-of-type(1) {
        opacity: 0.8;
      }
      .c, .e {
        stroke: $whiteColor;
      }
    }
    .rotate-pic {
      .a, .b, .c {
        fill: #ececec;
      }
      .b {
        opacity: 0.5;
      }
      .c {
        fill-rule: evenodd;
      }
    }
    .frame {
      border-radius: 12px;
      margin: auto;
      .idscan-frame {
        width: calc(85% - env(safe-area-inset-left) - env(safe-area-inset-right));
        height: calc(85% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        position: absolute;
        left: calc(7.5% + env(safe-area-inset-left));
        top: calc(7.5% + env(safe-area-inset-top));
        //margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
        overflow: visible;
        .a, .b, .c {
          fill: none;
        }
        .c, .e {
          stroke-dasharray: 0.1 15;
        }
        .a, .b {
          stroke-linecap: round;
          stroke-linejoin: round;
          opacity: 1;
        }
        .a, .c {
          stroke: $whiteColor;
          stroke-width: 6px;
        }
        .b, .e {
          stroke: $blackColor;
          stroke-width: 4px;
        }
        .d {
          clip-path: none;
        }
      }
      .animatable {
        .b, .e {
          stroke: $scanFrameAnimationColor;
        }
        .c, .e {
          animation: circle 3s infinite linear;
        }
      }
      .spacer {
        padding-top: calc(3% + env(safe-area-inset-top));
        padding-bottom: calc(1% + env(safe-area-inset-bottom));
        padding-left: calc(1% + env(safe-area-inset-left));
        padding-right: calc(1% + env(safe-area-inset-right));
        height: calc(95% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        .inner-frame {
          background: rgba($overlayBackgroundColor, $overlayOpacity);
          box-shadow: $buttonBoxShadow;
          border-radius: 5px;
          width: 100%;
          height: 100%;
          display: flex;
          .button {
            margin-top: 20px;
            width: 30%;
            display: block;
            min-width: 180px;
          }
          .timeouted-action {
            margin-top: 2%;
            font-size: 1em;
          }
          .first-button {
            margin-top: 30px;
          }
          .text {
            color: $whiteColor;
            line-height: 25px;
            font-size: 1.2em;
          }
          div {
            margin: auto;
            width: 90%;
            max-width: 1300px;
            div {
              outline: none;
              opacity: $overlayOpacity;
            }
            svg.pic {
              path.a,
              path.d {
                fill: $whiteColor;
              }
            }
            .button {
              opacity: $buttonOpacity;
              position: relative;
            }
          }
          .button-link {
            color: $buttonTextColor;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-id-document-scan-frame {
    .frame {
      height: calc(44% * 1.7777);
      width: 80vw;
      margin: auto;
      .spacer {
        padding: 8px;
        height: calc(100% - 16px);
        .inner-frame {
          div {
            width: 80vw;
            div {
              width: 95%;
            }
            svg.pic,
            svg.pic-paper-permit {
              height: 150px;
              width: 170px;
            }
            svg.pic-id {
              transform: rotate(90deg);
            }
            div.button {
              width: 30%;
            }
          }
        }
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-id-document-scan-frame .frame {
    width: calc(96vh * 1.7777);
    height: calc(68vh + 0px);
    .paper-permit {
      svg.pic-paper-permit {
        height: 115px;
      }
    }
    .spacer {
      .inner-frame {
        .text {
          line-height: 20px;
        }
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-id-document-scan-frame {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .frame {
      margin: auto;
      max-width: 85%;
      .e, .c {
        width: 99.5%;
      }
      .idscan-frame {
        height: 80%;
        top: 10%;
        //width: calc(90% - env(safe-area-inset-left) - env(safe-area-inset-right));
        //left: calc(5% + env(safe-area-inset-left));
      }
      .inner-frame {
        .pic {
          margin-bottom: 2%;
        }
      }
    }
  }
}

.App.desktop {
  .comp-id-document-scan-frame {
    position: relative;
    width: 45vw;
    height: calc(45vw / 1.6);
    margin: calc(5vh) auto;
    .frame {
      min-width: 350px;
    }
    .pic {
      width: 200px;
      height: 120px;
    }
  }
}

.App.mobile {
  .comp-id-document-scan-frame {
    .frame {
      .e, .c {
        width: 98%;
      }
    }
  }
}

//iPhone 5
@media (max-width: 374px) {
  .App.portrait-primary {
    .comp-id-document-scan-frame {
      .frame .spacer .inner-frame div {
        svg.pic,
        svg.pic-paper-permit {
          margin-bottom: 6vh;
        }
      }
    }
  }
}

@media (max-height: 620px) {
  .App.portrait-primary,
  .App.portrait-secondary {
    .comp-id-document-scan-frame .frame .spacer .inner-frame div svg.pic,
    .comp-id-document-scan-frame .frame .spacer .inner-frame div svg.pic-paper-permit {
      height: 108px;
      width: 125px;
    }
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
  .App.landscape-primary.mobile section .comp-id-document-scan-frame .frame .paper-permit svg.pic-paper-permit {
    height: 95px;
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .App.portrait-primary,
  .App.portrait-secondary {
    .comp-id-document-scan-frame .frame {
      height: 70vh;
    }
  }
  .App.landscape-primary section,
  .App.landscape-secondary {
    .comp-id-document-scan-frame .frame {
      width: 88%;
      height: 85%;
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .App.landscape-primary section,
  .App.landscape-secondary {
    .comp-id-document-scan-frame .frame .spacer {
      width: 97%;
    }
  }
}
