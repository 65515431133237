.App {
  .comp-desktop-not-supported {
    .button.button-big {
      max-width: 10em;
      margin: 1em auto;
    }
    .fill-area {
      background-color: $desktopNotSupportedHeaderBackgroundColor;
      text-align: center;
      font-size: 1.2em;
      .contents {
        min-height: 160px;
        padding: 20px;
      }
      .message {
        color: $whiteColor;
        font-size: 1.3rem;
        width: 70%;
        margin: 5vh auto;
      }
    }
    .qr-container {
      display: flex;
      justify-content: center;
      vertical-align: middle;
      padding: 30px 20px 20px;
      div {
        height: 200px;
        width: 200px;
        padding: 1em;
        background-color: #FFFFFF;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-desktop-not-supported {
    .button.button-big {
      max-width: 10em;
      margin: 1em auto;
    }
    .fill-area {
      .contents {
        min-height: 80px;
        .bigger {
          margin-top: 10px;
        }
      }
    }
    .solutions {
      .heading {
        font-size: 1.5em;
      }
      ul li {
        font-size: 1em;
      }
    }
  }
}

.App.desktop {
  .comp-desktop-not-supported {
    .button.button-big {
      max-width: 10em;
      margin: 1em auto;
    }
    .card {
      padding: 0;
    }
    .fill-area {
      border-radius: 12px 12px 0 0;
      .icon,
      .text {
        display: inline-block;
      }
      .text {
        text-align: left;
        padding-left: 20px;
      }
    }
  }
}
