.App {
  .comp-feedback {
    text-align: left;

    .heading {
      font-size: 2em;
      margin-top: 15px;
      line-height: 1em;
    }

    .text-caption {
      margin: 15px 20px 0;
      line-height: 1.5em;

      .message {
        margin-top: 20px;
      }
    }

    .heading {
      margin-left: 20px;
    }

    .rating-container {
      margin: 20px 0 0 20px;
    }

    .textarea-container {
      margin-top: 50px;

      [contenteditable] {
        border: 1px solid #707070;
        border-radius: 6px;
        margin: 20px;
        min-height: 50px;
        padding: 8px 12px;
      }
    }

    .button-container {
      text-align: center;
    }

    .footer {
      padding: 100px 0 150px;

      .scroll-to-top {
        float: right;
        width: 41px;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        cursor: pointer;
        align-items: center;
      }

      .back-button {
        float: left;
        margin-left: 20px;
      }
    }
  }
}