.App {
  .comp-success-pictogram {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .pic {
      width: 150px;
      height: 150px;
      margin-top: calc(50vh - 75px);
      .a,
      .b,
      .c {
        fill: $successCheckmarkColor;
      }
    }
  }
  .success-pictogram-overlay {
    background-color: $overlayBackgroundColor;
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-success-pictogram {
    .pic {
      margin-top: calc(50vh - 75px);
    }
  }
}

.App.desktop {
  .comp-success-pictogram {
    width: auto;
    height: auto;
    .pic {
      margin-top: 10vw;
    }
  }
  .success-pictogram-overlay {
    width: 100%;
    height: 100%;
  }
}

.App.mobile {
  .comp-success-pictogram {
    header {
      display: block;
    }
  }
}
