.App {
  .comp-terms {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-bottom: 50px;
    font-size: 0.88em;
    header {
      box-shadow: none;
    }
    .card {
      display: flex;
      flex-direction: column;
      flex: 1;
      .main-content {
        flex: 1 0 auto;
      }
    }
    h1 {
      margin: 0 auto 5%;
    }
    .agree-checkboxes {
      margin: 0 auto;
      display: block;
      width: 90%;
      .agree-checkbox {
        margin: 0 2% 8%;
        display: flex;
        height: 20%;
        height: max-content;
        min-height: 20%;
        width: 90%;
        height: max-content;
        min-height: 20%;
        .checkbox-container {
          width: 32px;
          height: 32px;
          margin: auto 4% auto 5%;
          flex-direction: column;
          input {
            width: 33px;
            height: 33px;
            border: none;
            opacity: 0;
            position: relative;
            margin-left: -1px;
            margin-top:-1px;
          }
        }
        .checkbox-container.inactive {
          background-image: $checkboxBackgroundImageInactive;
        }
        .checkbox-container.active {
          background-image: $checkboxBackgroundImageActive;
        }
        label {
          padding: 0;
          text-align: left;
          width: fit-content;
          margin: auto 0;
          .link {
            display: contents;
            color: $linkButtonColor;
            cursor: pointer;
          }
        }
      }
    }
    .agree-list {
      width: 90%;
      margin: 0 2%;
      font-size: 1em;
      ul {
        padding-inline-start: 14%;
        margin-block-start: 0;
        list-style-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojMzczMzNiO308L3N0eWxlPjwvZGVmcz48Y2lyY2xlIGNsYXNzPSJhIiBjeD0iNSIgY3k9IjUiIHI9IjUiLz48L3N2Zz4=");
        li {
          text-align: left;
          padding-inline-start: 6%;
          margin-top: 2%;
          margin-bottom: 20px;
          .link {
            display: contents;
            color: $linkButtonColor;
            cursor: pointer;
            span {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .button-container {
      padding-inline-start: 0;
      .button-big {
        width: 70%;
      }
      .active {
        background-color: $buttonBackgroundColor;
      }
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-terms {
    margin-top: 40px;
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-terms {
    h1 {
      margin-bottom: 2%;
    }
    .agree-checkbox {
      width: 90%;
      margin: 0 2% 3%;
      .checkbox-container {
        margin: auto 3% auto 3%;
      }
    }
    .agree-list {
      width: 90%;
      ul {
        display: flex;
        flex-direction: column;
        padding-inline-start: 10%;
        li {
          padding-inline-start: 3%;
          margin: auto auto 2% 0;
        }
      }
    }
    .button-container {
      .button {
        width: 40%;
        padding: 11px;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-terms {
    .agree-list {
      ul {
        padding-inline-start: 7%;
      }
    }
    .button-container {
      margin-top: 2%;
    }
  }
}

.App.desktop {
  .comp-terms {
    .card {
      display: flex;
      .button-container .button {
        width: 30%;
        min-width: 250px;
      }
      .agree-checkbox,
      .agree-list {
        width: 90%;
      }
    }
  }
}

.App.landscape-primary.desktop section {
  .comp-terms {
    .agree-checkbox,
    .agree-list {
      width: auto;
    }
  }
}

@media (max-width: 374px) {
  .App {
    section {
      .agree-checkbox {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 400px) {
  .agree-checkbox {
    margin-bottom: 0;
  }
  .comp-terms {
    .agree-list {
      ul {
        padding-inline-start: 9%;
        li {
          padding-inline-start: 5%;
        }
      }
    }
    .button-container {
      .button {
        width: 45%;
      }
    }
  }
  .App.landscape-primary.mobile section .comp-terms .agree-list ul {
    padding-inline-start: 13%;
  }
  .App section .comp-terms .agree-checkbox {
    width: 91%;
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
  .App.landscape-primary.mobile section .comp-terms .agree-list ul,
  .App.portrait-primary.mobile section .comp-terms .agree-list ul {
    padding-inline-start: 10%;
  }
}

/* Larger than tablet */
@media (min-width: 550px) {
  .App.landscape-primary.mobile section .comp-terms .agree-list ul {
    padding-inline-start: 7.5%;
  }
  .App section .comp-terms {
    .agree-checkbox .checkbox-container {
      margin-left: 6%;
    }
    .agree-list ul {
      li {
        padding-inline-start: 4%;
      }
    }
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .App.portrait-primary,
  .App.portrait-secondary {
    .App.portrait-primary.mobile section .comp-terms .agree-list ul {
      padding-inline-start: 10%;
    }
    .App.landscape-primary.mobile section .comp-terms .agree-list ul {
      padding-inline-start: 9%;
    }
  }
  .App.landscape-primary section,
  .App.landscape-secondary {
    .comp-terms {
      .agree-list {
        ul {
          padding-inline-start: 9%;
        }
      }
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .App.landscape-primary.mobile section,
  .App.landscape-secondary.mobile section {
    .comp-terms {
      h1 {
        margin-top: -3vh;
      }
    }
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  .App.landscape-primary section,
  .App.landscape-secondary {
    .comp-terms {
      .agree-checkbox {
        width: 50%;
        margin: 3% auto;
      }
      .agree-list {
        margin: 1% auto;
        width: 50%;
        ul {
          padding-inline-start: 9%;
          li {
            padding-inline-start: 3%;
          }
        }
      }
    }
  }
}
