.App {
  .comp-qrcode-scanner {
    display: flex;
    flex: 1;
    video {
      width: 100vw;
      height: 100%;
      flex: 1;
      display: flex;
      object-fit: cover;
      background: $videoBackgroundColor;
    }
  }
}

.App.desktop {
  .comp-qrcode-scanner {
    height: auto;
    flex-direction: column;
    .card {
      padding: 0;
      position: relative;
    }
    video {
      border-radius: 12px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
    }
  }
}

.App.mobile {
  .comp-qrcode-scanner {
    header {
      display: none;
    }
  }
}
