.App {
  .comp-selfie-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .frame {
      position: relative;
      width: 45vh;
      height: 70vh;
      margin: 12vh auto auto;
      .selfie-frame {
        width: 100%;
        height: 100%;
        .a, .b {
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 0 15;
          opacity: 1;
          fill: none;
        }
        .a {
          .c {
            stroke: $whiteColor;
            stroke-width: 6px;
            stroke-dasharray: 0 15;
          }
        }
        .b {
          stroke: $blackColor;
          stroke-width: 4px;
        }
        svg.user-pic {
          margin: 0 auto 80px;
          .a {
            fill: $whiteColor;
          }
          .b, .c {
            fill:none;
            stroke-linecap:round;
          }
          .b {
            stroke:$whiteColor;
            stroke-width:2px;
            stroke-dasharray:0.001 2;
          }
          .c {
            stroke:#37333b;
            stroke-width:3px;
            stroke-dasharray:0.001 4.22;
          }
        }
      }
      .animatable {
        .b {
          stroke: $scanFrameAnimationColor;
        }
        .c,
        .e {
          animation: circle 3s infinite linear;
        }
      }
      .processing-frame {
        .b {
          stroke: $selfieFrameProcessingColor;
        }
      }
      .error-frame {
        .b {
          stroke: $qrCodeErrorFrameColor;
        }
      }
      .text {
        position: relative;
        top: -2vw;
        width: 100%;
        background: $selfieRecordingTextBackgroundColor;
        margin: auto;
        opacity: 0.8;
        border-radius: 4px;

        div {
          color: $lookIntoTheCameraTextColor;
        }
      }
      .selfie-overlay {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        width: 90%;
        height: 100%;
        opacity: 0.8;
        .a, .b {
          .c, .e {
            fill: $overlayBackgroundColor;
            stroke-linecap: butt;
          }
        }
      }
      .selfie-instruction,
      .selfie-fail-section {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        height: 100%;
        .face-frame,
        .fail-icon {
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          top: 12vh;
          left: 0;
          right: 0;
          width: 100px;
          height: 100px;
          .a {
            fill: #fff;
            stroke: #fff;
            stroke-width: 0;
          }
          .b, .c {
            stroke-dasharray: 0 15;
          }
          .c {
            stroke: #fff;
            stroke-width: 4px;
          }
        }
        .selfie-instruction-message,
        .selfie-error-message {
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          top: 32vh;
          left: 0;
          right: 0;
          .instruction-text,
          .error-message {
            color: #fff;
            width: 77%;
            margin: auto;
          }
          .button-with-loader {
            display: flex;
            position: relative;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .button-big {
              max-width: max-content;
            }
            .loader {
              position: relative;
              bottom: -0.5em;
              left: 1em;
            }
          }
          .button-big {
            margin-top: 20px;
            width: auto;
            min-width: 130px;
            white-space: nowrap;
          }
          .button-big.inactive {
            background: $inactiveButtonBackgroundColor;
            border: $inactiveButtonBorder;
            color: $inactiveButtonTextColor;
          }
        }
      }
      .selfie-instruction.fullscreen {
        .face-frame {
          top: 16vh;
        }
        .selfie-instruction-message {
          top: 36vh;
        }
      }
      .selfie-fail-section {
        .selfie-error-message {
          .timeouted-action {
            margin-top: 2%;
            font-size: 1em;
          }
          .first-button {
            margin-top: 30px;
          }
          .button-link {
            color: $buttonTextColor;
            text-decoration: underline;
            margin-top: 10px;
          }
        }
      }
      .waiting-frame-section {
        position: absolute;
        color: $whiteColor;
        margin-left: auto;
        margin-right: auto;
        top: 12vh;
        left: 0;
        right: 0;
        .waiting-frame {
          width: 110px;
          height: 110px;
          animation: dash 10s linear infinite;
          .c {
            stroke: $selfieSpinnerColor;
          }
        }
        .waiting-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $selfiePleaseWaitTextColor;
          font-size: 3.5vw;
          max-width: 100px;
        }
      }
      .processing-text {
        margin-left: auto;
        margin-right: auto;
        top: 40vh;
        left: 0;
        right: 0;
        position: absolute;
        div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $whiteColor;
          line-height: 25px;
          width: 195px;
          height: 100px;
        }
      }
    }
  }
  .selfie-frame-overlay {
    background-color: $overlayBackgroundColor;
    .loading-frame-section {
      .loader {
        width: 3rem;
        height: 3rem;
        top: calc((100vh - 3rem) / 2);
        left: calc((100vw - 3rem) / 2);

      }
      .waiting-text {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $selfiePleaseWaitTextColor;
        font-size: 5vw;
        max-width: 100px;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-selfie-frame {
    .frame {
      width: 65vh;
      height: 85vh;
      .processing-text {
        top: 60vh;
        div {
          width: 165px;
          font-size: 1em;
          line-height: 18px;
        }
      }
      .waiting-frame-section {
        .waiting-text {
          font-size: 3.5vh;
        }
      }
      .selfie-fail-section {
        .fail-icon {
          width: 80px;
          height: 80px;
          top: 8vh;
        }
        .selfie-error-message {
          top: 38vh;
        }
      }
    }
  }
  .selfie-frame-overlay {
    .loading-frame-section {
      .loader {
        top: 50vh;

      }
      .waiting-text {
        top: 30%;
      }
    }
  }
}

.App.desktop {
  .comp-selfie-frame {
    width: 50vw;
    height: 57vh;
    header {
      display: none;
    }
    .frame text {
      position: relative;
      left: 0;
      width: 45vw;
      margin: auto;
    }
    .frame {
      width: 75%;
      height: 75%;
      margin: 6% auto;
      min-width: 350px;
      .selfie-overlay {
        height: 90%;
        margin-top: 3%;
      }
      .selfie-instruction,
      .selfie-fail-section {
        .face-frame,
        .fail-icon {
          top: 15%;
        }
        .selfie-instruction-message,
        .selfie-error-message {
          top: 48%;
          .instruction-text,
          .error-message {
            width: 45%;
            max-width: 190px;
          }
        }
      }
      .waiting-frame-section {
        top: 6vh;
      }
      .processing-text {
        top: 30vh;
        div {
          width: 200px;
          height: 100px;
        }
      }
    }
    .waiting-frame-section {
      .waiting-text {
        font-size: 1.2em;
      }
    }
  }
  .selfie-frame-overlay {
    width: 100%;
    height: 100%;
  }
}

.App.mobile {
  .comp-selfie-frame {
    header {
      display: block;
    }
  }
}

.App.portrait-primary.mobile, .App.portrait-secondary {
  .comp-selfie-frame {
    .selfie-instruction.fullscreen {
      .face-frame {
        top: 14vh;
      }
      .selfie-instruction-message {
        top: 34vh;
      }
      .selfie-overlay {
        width: 88%;
      }
    }
  }
}

@media (max-width: 320px) {
  .App.portrait-primary {
    section {
      .comp-selfie-frame {
        .selfie-instruction, .selfie-instruction.fullscreen {
          .face-frame {
            top: 7vh!important;
          }
    
          .selfie-instruction-message {
            top: 28vh!important;
          }
        }
        .waiting-frame-section {
          top: 7vh!important;
        }
        .selfie-overlay {
          width: 87%!important;
        }
      }
    }
  }
}

//iPhone 5
@media (max-width: 374px) {
  .App.portrait-primary {
    section {
      .comp-selfie-frame {
        .selfie-instruction, .selfie-instruction.fullscreen {
          .face-frame {
            top: 12vh !important;
          }
          .selfie-instruction-message {
            top: 32vh !important;
          }
        }
      }
    }
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
  .App.portrait-primary.mobile, .App.portrait-secondary {
    .comp-selfie-frame {
      .selfie-instruction.fullscreen {
        .face-frame {
          top: 18vh;
        }
        .selfie-instruction-message {
          top: 38vh;
        }
      }
      .selfie-instruction {
        .face-frame {
          top: 10vh;
        }
        .selfie-instruction-message {
          top: 30vh;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .App.portrait-primary,
  .App.portrait-secondary {
    .comp-selfie-frame {
      .frame {
        width: 85%;
        height: 70%;
        margin: 25% auto;
      }
    }
  }
}

@media (max-height: 670px) {
  .App.portrait-primary,
  .App.portrait-secondary {
    .comp-selfie-frame {
      .frame {
        height: 60vh;
        margin-top: 20%;
      }
    }
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .App.portrait-primary,
  .App.portrait-secondary {
    .comp-selfie-frame {
      .frame {
        width: 70%;
        height: 70%;
        margin: 21% auto;
      }
    }
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  .App.landscape-primary section,
  .App.landscape-secondary {
    .comp-selfie-frame {
      .frame {
        width: 75%;
        height: 85%;
        margin: 6% auto;
      }
    }
  }
}
