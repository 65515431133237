.App {
  .comp-transaction-code {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-bottom: 50px;
    .card {
      display: flex;
      flex-direction: column;
      flex: 1;
      .contents {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .main-content {
        flex: 1 0 auto;
      }
    }
    .contents {
      display: flex;
      flex-direction: column;
      .congrats {
        font-size: 2em;
        margin: 20px 0 10px;
      }
      .link {
        font-size: 1.3em;
        color: $linkButtonColor;
        cursor: pointer;
        margin-bottom: 30px;
        span {
          border-bottom: 1px solid $linkButtonColor;
        }
      }
      .next-button {
        margin-top: 20px;
      }
      .button-big {
        min-width: 200px;
        margin: 75px auto 0;
      }
      .service-provider-logo {
        margin-top: 2.5em;
        .a {
          fill: #f8f8f8;
        }
        .b {
          fill: #a4a3a3;
        }
        .sp-logo, .default-sp-logo {
          max-width: 200px;
          max-height: 150px;
        }
      }
    }
    .transaction-code-with-loader {
      display: flex;
      justify-content: center;
      .tc-loader {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 78%;
        max-width: 360px;
        margin: 30px auto 0;
        .loader {
          margin: 0;
          right: -35px;
          pointer-events: none;
        }
      }
    }
    .react-code-input {
      input {
        font-family: $inputFontFamily !important;
        width: 9% !important;
        max-width: 37px !important;
        height: 55px !important;
        background-color: #f2f3f4 !important;
        margin: 2px !important;
        padding: 5px !important;
        opacity: 1;
      }
      input::selection {
        background: none !important;
      }
      input:nth-child(3n):not(:nth-child(9)) {
        margin-right: 10px !important;
      }
      input:nth-child(9) {
        margin-right: 0 !important;
      }
      input:focus {
        outline: none !important;
        caret-color: transparent !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 1' width='30' height='1'%3E%3Cstyle%3E tspan %7B white-space:pre %7D .shp0 %7B fill: %23000000 %7D %3C/style%3E%3Cpath id='svg_5' class='shp0' d='M1.25 0L28.75 0C29.44 0 30 0.11 30 0.25L30 0.75C30 0.89 29.44 1 28.75 1L1.25 1C0.56 1 0 0.89 0 0.75L0 0.25C0 0.11 0.56 0 1.25 0Z' /%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 18px;
        background-position-y: 45px;
        background-position-x: center;
      }
      input[value=""] {
        background-color: #e6e9ec !important;
      }
      input[data-valid="false"] {
        color: #d50024 !important;
        background-color: #f7ccd3 !important;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .error {
      color: $errorTextColor;
      height: 10px;
      margin-top: 15px;
    }
    .text {
      margin-top: 50px;
    }
    .contents {
      .link {
        font-size: 1em;
        margin: 15px 0 0 0;
      }
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .react-code-input {
    input {
      height: 44px !important;
      font-size: 27px !important;
      padding: 3px !important;
    }
    input:focus {
      background-position-y: 36px;
    }
  }
}

.App.desktop {
  .comp-transaction-code {
    .card {
      display: flex;
    }
  }
}

//iPhone 5, SE
@media (max-width: 375px) {
  .App {
    .comp-transaction-code {
      .contents {
        .service-provider-logo {
          margin-top: 0.5em;
        }
      }
      .transaction-code-with-loader {
        .tc-loader {
          justify-content: center;
          width: 74%;
          .react-code-input {
            input {
              width: 8% !important;
              height: 35px !important;
              padding: 0 !important;
              font-size: 27px !important;
            }
            input:focus {
              background-position-y: 28px;
            }
          }
        }
      }
      .text {
        margin-top: 40px;
      }
    }
  }
}
