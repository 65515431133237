.App {
  .comp-additional-doc-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    .additional-doc-pic,
    .fail-pic {
      width: 100px;
      height: 63px;
      margin-bottom: 20px;
    }
    .frame {
      width: 85%;
      height: 87%;
      border-radius: 12px;
      margin: auto;
      .additional-doc-frame {
        width: 90%;
        height: 90%;
        position: absolute;
        left: 5%;
        top: 5%;
        overflow: visible;
        .a, .b, .c {
          fill: none;
        }
        .c, .e {
          stroke-dasharray: 0.1 15;
        }
        .a, .b {
          stroke-linecap: round;
          stroke-linejoin: round;
          opacity: 1;
        }
        .a, .c {
          stroke: $whiteColor;
          stroke-width: 6px;
        }
        .b, .e {
          stroke: $blackColor;
          stroke-width: 4px;
        }
        .d {
          clip-path: none;
        }
      }
      .spacer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        height: calc(100% - 16px);
        .inner-frame {
          background: rgba($overlayBackgroundColor, $overlayOpacity);
          box-shadow: $buttonBoxShadow;
          border-radius: 5px;
          width: 100%;
          height: 100%;
          display: flex;
          .additional-doc-pic {
            margin: auto;
          }
          .button {
            margin-top: 20px;
            width: 30%;
            display: block;
            min-width: 180px;
          }
          .text {
            color: $whiteColor;
            line-height: 25px;
            font-size: 1.2em;
          }
          div {
            margin: auto;
            width: 90%;
            max-width: 1300px;
            div {
              outline: none;
              opacity: $overlayOpacity;
            }
            .button {
              opacity: $buttonOpacity;
              position: relative;
            }
          }
        }
      }
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-additional-doc-message {
    .frame {
      height: calc(44% * 1.7777);
      width: 80vw;
      margin: auto;
      .spacer {
        padding: 8px;
        height: calc(100% - 16px);
        .inner-frame {
          div {
            width: 80vw;
            div {
              width: 95%;
            }
            svg.additional-doc-pic,
            svg.fail-pic {
              height: 150px;
              width: 170px;
            }
            div.button {
              width: 30%;
            }
          }
        }
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-additional-doc-message .frame {
    width: calc(96vh * 1.7777);
    height: calc(68vh + 0px);
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-additional-doc-message {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .frame {
      margin: auto;
      max-width: 85%;
      .e, .c {
        width: 99.5%;
      }
      .additional-doc-frame {
        height: 80%;
        top: 9%;
      }
    }
  }
}

.App.desktop {
  .comp-additional-doc-message {
    position: relative;
    width: 45vw;
    height: calc(45vw / 1.6);
    margin: calc(5vh) auto;
    .frame {
      min-width: 350px;
      svg {
        margin-top: 0px;
      }
    }
  }
}

.App.mobile {
  .comp-additional-doc-message {
    .frame {
      .e, .c {
        width: 98%;
      }
    }
  }
}

@media (max-height: 620px) {
  .App.portrait-primary,
  .App.portrait-secondary {
    .comp-additional-doc-message .frame .spacer .inner-frame div svg.additional-doc-pic,
    .comp-additional-doc-message .frame .spacer .inner-frame div svg.fail-pic {
      height: 108px;
      width: 125px;
    }
    .comp-additional-doc-message .frame {
      .spacer .inner-frame div svg.additional-doc-pic {
        margin: auto;
      }
    }
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  .App.landscape-primary section,
  .App.landscape-secondary {
    .comp-additional-doc-message .frame {
      width: 88%;
      height: 85%;
    }
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  .App.landscape-primary section, .App.landscape-secondary {
    .comp-additional-doc-message .frame .spacer {
      width: 97%;
    }
  }
}
