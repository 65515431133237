.App {
  .comp-device-not-supported {
    .button.button-big {
      max-width: 10em;
      margin: 1em auto;
    }
    .fill-area {
      background-color: $deviceIsNotSupportedHeaderBackgroundColor;
      text-align: center;
      font-size: 1.2em;
      .contents {
        min-height: 160px;
        padding: 20px;
        .icon {
          .pictogram {
            width: 9rem;
            height: 9rem;
            .a {
              fill: $whiteColor;
            }
          }
        }
      }
      div {
        color: $whiteColor;
        .bigger {
          font-size: 1.7rem;
        }
        .smaller {
          font-size: 1.1rem;
          margin: auto;
        }
      }
    }
    .solutions {
      text-align: left;
      padding: 30px 20px 20px;
      .heading {
        font-size: 1.7rem;
        color: $black2Color;
      }
      .message {
        margin-bottom: 20px;
      }
      ul {
        padding: 0;
        list-style: none;
        margin-top: 20px;

        li {
          font-size: 1em;
          margin: 10px 0;

          span {
            border-bottom: 1px solid #d2d2d2;
          }
          a {
            text-decoration: none;
            color: unset;
          }
        }
      }
      ul.list {
        list-style: disc;
        padding-left: 20px;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-device-not-supported {
    .button.button-big {
      max-width: 10em;
      margin: 1em auto;
    }
    .fill-area {
      .contents {
        min-height: 80px;
        .bigger {
          margin-top: 10px;
        }
      }
    }
    .solutions {
      .heading {
        font-size: 1.5em;
      }
      ul li {
        font-size: 1em;
      }
    }
  }
}

.App.desktop {
  .comp-device-not-supported {
    .button.button-big {
      max-width: 10em;
      margin: 1em auto;
    }
    .card {
      padding: 0;
    }
    .fill-area {
      border-radius: 12px 12px 0 0;
      .icon,
      .text {
        display: inline-block;
      }
      .text {
        text-align: left;
        padding-left: 20px;
      }
    }
  }
}
