.App {
  .comp-capture-image {
    .capture-image-frame {
      height: calc(75% - env(safe-area-inset-top));
      top: calc(3% + env(safe-area-inset-top));
      width: calc(85% - env(safe-area-inset-left) - env(safe-area-inset-right));
      left: calc(7.5% + env(safe-area-inset-left));
      position: absolute;
      overflow: visible;
      .a, .b, .c {
        fill: none;
      }
      .c, .e {
        stroke-dasharray: 0.1 15;
      }
      .a, .b {
        stroke-linecap: round;
        stroke-linejoin: round;
        opacity: 1;
      }
      .a, .c {
        stroke: $whiteColor;
        stroke-width: 6px;
      }
      .b, .e {
        stroke: $blackColor;
        stroke-width: 4px;
      }
      .d {
        clip-path: none;
      }
    }
    .capture-button-section {
      width: 100%;
      height: 15%;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto auto 0;
      .button {
        position: absolute;
        width: 5rem;
        height: 5rem;
        background-color: $additionalDocShotButtonColor;
        border-radius: 50%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
      .button:after {
        content: "";
        border-radius: 50%;
        border: 0.15rem solid $black2Color;
        position: absolute;
        top: 0.3rem;
        left: 0.3rem;
        bottom: 0.3rem;
        right: 0.3rem;
      }
    }
    .capture-button-background {
      background-color: $blackColor;
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-capture-image {
    .capture-image-frame {
      height: calc(85% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      top: calc(5% + env(safe-area-inset-top));
      width: calc(75% - env(safe-area-inset-left));
      left: calc(5% + env(safe-area-inset-left));
    }
    .capture-button-section {
      width: 15%;
      height: 100%;
      margin: auto 0 auto auto;
    }
  }
}

.App.desktop {
  .comp-capture-image {
    position: relative;
    width: 45vw;
    height: calc(45vw / 1.6);
    margin: calc(5vh) auto;
  }
}
