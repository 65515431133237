.App {
  .comp-start-text {
    .button-container {
      width: 100%;
      .button-link {
        margin-bottom: 30px;
        margin-top: 10px;
        font-size: 0.88em;
        color: $linkButtonColor;
        cursor: pointer;
      }
    }
    .text-caption {
      margin: 0 calc(50vw - 150px);
      line-height: 22px;
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-start-text {
    .button-container {
      bottom: 5vh;
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-start-text {
    .text-caption {
      margin: 0 calc(50vw - 200px);
    }
    .button-link {
      margin: 20px auto 10px;
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-start-text {
    .button-container {
      margin-bottom: 5vh;
    }
  }
}

.App.desktop {
  .comp-start-text {
    .text-caption {
      margin: auto;
    }
    .button-container {
      margin-top: 60px;
    }
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  .App.landscape-primary section,
  .App.landscape-secondary {
    .comp-start-text {
      .button-container {
        bottom: 5vh;
      }
    }
  }
}
