@font-face {
    font-family: 'Vista Sans OT';
    src: url(fonts/VistaSansOT.eot); /* IE9*/
    src: url(fonts/VistaSansOT-ieFix.eot) format("embedded-opentype"), /* IE6-IE8 */
    url(fonts/VistaSansOT.woff2) format("woff2"), /* chrome firefox */
    url(fonts/VistaSansOT.woff) format("woff"), /* chrome firefox */
    url(fonts/VistaSansOT.ttf) format("truetype"); /* chrome firefox opera Safari, Android, iOS 4.2+*/
};

/* generic */
$fontFamily: 'Vista Sans OT', sans-serif; // The main font of the texts in the application. Type: Font name. Ex: 'Montserrat', sans-serif;
$inputFontFamily: 'Vista Sans OT'; // The font of the text inside inputs(Currently we have only transaction code enter input). Type: Font name. Ex: 'Roboto Mono', monospace;
$dataFontFamily: 'Vista Sans OT', sans-serif; // The font of the text in Data page where showing data read from documetns. Type: Font name. Ex: 'Montserrat', monospace;
$primaryBackgroundColor: #FFFFFF; // The main background color of application. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #FFF;
$videoBackgroundColor: #FFFFFF; // The main background color of video component until video streaming starts. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #FFF;
$primaryTextColor: #004D8C; // Color of the text in the application. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #1A1818;
$primaryLinkColor: #004D8C; // Color of the links in the application. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #1A1818;
$linkButtonColor: #8E0554; // Color of the link buttons(functional buttons looks like links, ex. Compatibility notes, Privacy Policy, etc.) in the application. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #E9425E;
$activeLanguageColor: #8E0554; // Color of the active/selected language. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #E9425E;
$inactiveLanguageColor: #004D8C; // Color of the inactive language. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #E9425E;
$languageSelectorPopupBackgroundColor: #FFFFFF; // Color of language select popup background. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #242423;
$dataImagesBackgroundColor: #e6e6e6; // Used as a background color of Images in the Data page. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #ECECEC;
$successCheckmarkColor: #9EC312; // Color of the success checkmark. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #38B1EA;
$transactionCodeInputTextColor: #004d8c; // Color of the transaction code input text. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #38B1EA;
$lookIntoTheCameraTextColor: #004D8C; // Color of the instruction text during selfie recording. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #ECECEC;
$overlayBackgroundColor: #004D8C; // Background color of overlay message of Id Scan, Selfie and Offline. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #1C1B1B;
$errorTextColor: #FF0000; // Text color of Error messages. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #FF0000;
$additionalDocShotButtonColor: #FBFBFB; // Background color of the additional doc scanning shooter. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #FBFBFB;
$deviceIsNotSupportedHeaderBackgroundColor: #00A1ED; // Background color of the Device is not supported page. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #00A1ED;
$desktopNotSupportedHeaderBackgroundColor: #00A1ED; // Background color of the Desktop is not supported page. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #00A1ED;
$headerHeight: 80px; // Height of the header, the distance between header and main content. Type: Size unit, em, rem, %, px, etc.. Ex: 75px;
$logoWidth: 60px; // Width of the main logo. Type: Size unit, em, rem, %, px, etc.. Ex: 64px;
$overlayOpacity: 0.8; // Opacity of the overlay of id scan, selfie and offline. Type: Number from 0.0 (fully transparent) to 1.0 (fully opaque). Ex: 0.8;
$scanFrameAnimationColor: #00c14e; // Color of the animated frame during verification. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #00FF4C;
$qrCodeErrorFrameColor: #FF0000; // QR Code scan error message frame color. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #7b2a33;
$loaderBorderColor: #FF0000; // Color of loading spinner border which appear when sharing data or entering transaction code. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #E9425E;
$selfieFrameProcessingColor:  #00A0E0; // Color of animated frame when selfie verification status is processing(waiting answer from BE). Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #00A1ED;
$selfieSpinnerColor: #9EC312;  // Color of the wait animated frame when verification status is processing. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #00A1ED;
$selfiePleaseWaitTextColor: #9EC312;  // Color of the please wait text when verification status is processing. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #00A1ED;
$forwardArrow: #c6467b; // Color of the forward arrow icon on select type screen. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #E9425E;
$selfieRecordingTextBackgroundColor: transparent; // Background color of overlay message of Selfie recording and preparation text. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #1C1B1B;
$endPageSuccessCheckmarkColor: #9EC312; // Color of the success checkmark on end success and end warning pages. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #38B1EA;

/* buttons */
$buttonBackgroundColor: #004D8C; // Color of all buttons. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #E9425E;
$buttonTextColor: #ffffff; // Color of the text in all buttons. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #FFF;
$buttonBorderRadius: 24px; // Border radius of buttons, gives roudness. Type: Size unit, em, rem, %, px, etc.. Ex: 30px;
$buttonBoxShadow: 0px 3px 6px #333333; // Attaches shadow to the buttons. Values: none|h-offset(Required Size unit) v-offset(Required Size unit) blur(Optional Size unit) spread(Optional Size unit) color(Color) |inset|initial|inherit;. Ex: 0px 3px 6px #00000029;
$buttonOpacity: 1; // Opacipty of the buttons. Type: Number from 0.0 (fully transparent) to 1.0 (fully opaque). Ex: 0.8;
$buttonBorder: 0; // Border type of buttons. Values: dotted, dashed, solid, double, groove, ridge, inset, outset, none, hidden.
$inactiveButtonTextColor: #B4B4B4; // Text color of disabled buttons. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #fff;
$inactiveButtonBorder: 3px solid #B4B4B4; // Border type of disabled buttons. Values: dotted, dashed, solid, double, groove, ridge, inset, outset, none, hidden.
$inactiveButtonBackgroundColor: #fff; // Color of disabled buttons. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #BBB;
$overlayButtonBackgroundColor: #ffffff; // Color of overlay buttons. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #E9425E;
$overlayButtonTextColor: #004D8C; // Color of the text in overlay buttons. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #FFF;

/**/
// Image of checked checkbox in the Terms of Service page. Type: URL with base64 image. Ex: url("data:image/svg+xml;base64,...");
$checkboxBackgroundImageActive: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48ZGVmcz48c3R5bGU+LmEsLmN7ZmlsbDpub25lO30uYXtzdHJva2U6IzAwMDtzdHJva2Utd2lkdGg6MnB4O30uYntzdHJva2U6bm9uZTt9PC9zdHlsZT48L2RlZnM+PGcgY2xhc3M9ImEiPjxyZWN0IGNsYXNzPSJiIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHJ4PSIzIi8+PHJlY3QgY2xhc3M9ImMiIHg9IjEiIHk9IjEiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgcng9IjIiLz48L2c+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNS43NDcgOC45MDQpIj48cmVjdCB3aWR0aD0iMyIgaGVpZ2h0PSIxMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA3LjA3MSkgcm90YXRlKC00NSkiLz48cmVjdCB3aWR0aD0iMyIgaGVpZ2h0PSIxOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTguMzg1KSByb3RhdGUoNDUpIi8+PC9nPjwvc3ZnPg==");
// Image of unchecked checkbox in the Terms of Service page. Type: URL with base64 image. EX: url("data:image/svg+xml;base64,...").
$checkboxBackgroundImageInactive: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO3N0cm9rZTojNzA3MDcwO3N0cm9rZS13aWR0aDoycHg7fS5ie2ZpbGw6I2YxZWZlZjt9LmN7c3Ryb2tlOm5vbmU7fS5ke2ZpbGw6bm9uZTt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYwIC00ODQpIj48ZyBjbGFzcz0iYSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjAgNDg0KSI+PHJlY3QgY2xhc3M9ImMiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgcng9IjMiLz48cmVjdCBjbGFzcz0iZCIgeD0iMSIgeT0iMSIgd2lkdGg9IjMwIiBoZWlnaHQ9IjMwIiByeD0iMiIvPjwvZz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMykiPjxyZWN0IGNsYXNzPSJiIiB3aWR0aD0iMyIgaGVpZ2h0PSIxMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjguNzQ3IDQ5OS45NzUpIHJvdGF0ZSgtNDUpIi8+PHJlY3QgY2xhc3M9ImIiIHdpZHRoPSIzIiBoZWlnaHQ9IjE4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4Ny4xMzIgNDkyLjkwNCkgcm90YXRlKDQ1KSIvPjwvZz48L2c+PC9zdmc+");
$resultSuccessBackground: #9ec312; // Success background color of LFV section in the data page. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #38B1EA;
$resultFailedBackground: #ff0000; // Faulure background color of LFV section in the data page. Type: Color name, Hexadecimal rgb, rgb, rgba. Ex: #E9425E;
