.App {
  .custom-input {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 7px 0;

    > label {
      width: 30%;
      text-align: right;
      overflow-wrap: break-word;
      margin-right: 20px;
    }

    input {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #443c3c;
      border-radius: 4px;
      padding: 3px 6px;
    }

    input:focus {
      outline: 1px solid #000000;
      border-color: #000000;
    }

    &.error-input {
      input {
        border: 1px solid #ae1515;
      }

      .custom-menu__control {
        border-color: #ae1515;
      }

      .custom-menu__control select:focus {
        outline: 1px solid #ff0000;
      }

      input:focus {
        outline: 1px solid #ff0000;
        border-color: #ff0000;
      }
    }
  }

  .input-container {
    flex-grow: 1;
  }

  .inputs-horizontal-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .custom-input {
      flex-direction: column;
      margin: 0 10px;
      > label {
        text-align: center;
        margin: 0;
        width: 100%;
      }
      .input-container {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 450px) {
    .inputs-horizontal-block {
      flex-direction: column;
    }

    .custom-input {
      align-items: center;
      flex-direction: column;

      > label {
        text-align: center;
        margin: 0;
        width: 100%;
      }

      .input-container {
        width: 100%;
      }
    }
  }
}
