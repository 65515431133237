.App {
  .comp-poweredby-logo {
    position: absolute;
    bottom: 0;
    text-align: left;
    font-size: 0.6rem;
    display: flex;
    align-items: center;
    margin: 0 0 5px 5px;
    z-index: 9999;
    svg {
      width: 25px;
      height: 25px;
      margin-right: 3px;
    }
    .text {
      color: $blackColor;
    }
    .powered-text {
      color: $whiteColor;
      .cls-1 {
        fill: $whiteColor;
      }
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .powered-element {
    bottom: 15%;
  }
}
