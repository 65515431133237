@import 'abstracts/__vars.scss';
@import 'abstracts/__constants.scss';
@import 'routes/ViewController.scss';
@import 'components/header/Header.scss';
@import 'modules/start/Start.scss';
@import 'components/startText/StartText.scss';
@import 'components/startText/StartText.scss';
@import 'components/termsText/TermsText.scss';
@import 'modules/terms/Terms.scss';
@import 'modules/thankYou/ThankYou.scss';
@import 'modules/environmentTest/EnvironmentTest.scss';
@import 'modules/verifyCode/VerifyCode.scss';
@import 'DocumentVerification.scss';
@import 'components/frame/Frame.scss';
@import 'components/selfieFrame/SelfieFrame.scss';
@import 'components/successPictogram/SuccessPictogram.scss';
@import 'components/additionalDocMessage/AdditionalDocMessage.scss';
@import 'components/documentOverlay/DocumentOverlay.scss';
@import 'components/manualCaptureMessage/ManualCaptureMessage.scss';
@import 'components/documentBackSideMessage/DocumentBackSideMessage.scss';
@import 'components/captureImage/CaptureImage.scss';
@import 'components/poweredByLogo/PoweredByLogo.scss';
@import 'modules/verificationEnd/VerificationEnd.scss';
@import 'components/qrCodeMessage/QrCodeMessage.scss';
@import 'components/raitingItem/RaitingItem.scss';
@import 'modules/somethingWentWrong/SomethingWentWrong.scss';
@import 'modules/deviceNotSupported/DeviceNotSupported.scss';
@import 'components/noInternetConnection/NoInternetConnection.scss';
@import 'components/langSwitch/LangSwitch.scss';
@import 'components/loader/Loader.scss';
@import 'components/swipe/Swipe.scss';
@import 'modules/desktopNotSupported/DesktopNotSupported.scss';
@import 'modules/selfDeclaration/SelfDeclaration.scss';
@import 'components/inputs/SimpleInput.scss';
@import 'components/inputs/PhoneInput.scss';
@import 'components/formAddress/FormAddress.scss';
@import 'modules/informationForMtan/InformationForMtan.scss';
@import 'modules/transactionCode/TransactionCode.scss';
@import 'modules/documentVerification/DocumentVerification.scss';
@import 'modules/utilityBill/UtilityBill.scss';
@import 'components/utilityBillMessage/UtilityBillMessage.scss';
@import 'modules/contract/Contract.scss';
@import 'modules/deviceTimeIsWrong/DeviceTimeIsWrong.scss';
@import 'modules/newVersionIsAvailable/NewVersionIsAvailable.scss';
@import 'components/translate/Translate.scss';


/* Mobile first queries */
body {
  margin: 0;
}
body, html, #root, div.App {
  height: 100%;
  width: 100%;
  background: $primaryBackgroundColor;
  scroll-behavior: smooth;
}
h1 {
  font-weight: normal;
  font-size: 2em;
  margin: 10px auto;
}
body, html, button, input, div, textarea {
  font-family: $fontFamily;
  color: $primaryTextColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 1;
    }
}
@keyframes circle {
  from { stroke-dashoffset: 50%; }
}
@keyframes dash {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
