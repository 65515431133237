.App {
  .comp-dv.utility-billy {
    height: calc(100%);
  }
  .comp-utility-bill {
    height: 100%;
    display: flex;
    .captured-section {
      height: 100%;
      display: flex;
      .confirmation-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          margin: auto;
          padding: 10px;
        }
        .ReactCrop.image-preview {
          max-width: 60%;
          margin: auto;
          .ReactCrop__crop-selection {
            box-shadow: unset !important;
          }
        }
        .confirmaition-links {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          width: 90%;
          margin: auto;
          padding: 10px;
          .button-big {
            font-size: 1em;
            width: auto;
            min-width: 30%;
            margin: 0;
            padding: 10px;
            svg {
              width: 20px;
              height: 20px;
              .a {
                stroke: $whiteColor;
              }
            }
          }
        }
      }
      .loader-section {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: calc(90%);
        }
        div {
          height: 10%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-utility-bill {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .ReactCrop {
      max-width: 40% !important;
    }
    .confirmaition-links {
      width: 50%;
    }
  }
}

.App.desktop {
  .comp-dv.utility-billy {
    height: calc(100vh + 0px);
  }
  .comp-utility-bill {
    position: relative;
    width: 45vw;
    height: calc(45vw / 1.6);
    margin: calc(5vh) auto;
    .ReactCrop {
      height: calc(100%);
    }
    .loader-section {
      img {
        width: 90%;
      }
      div {
        height: 0;
        margin-top: 3vh;
        span {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
}
