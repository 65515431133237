.App {

  .section-container {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > label {
      width: 100%;
      text-align: center;
    }
  }
  
  .personal-details-page {
    section {
      height: 100%;
      width: 100%;
      text-align: center;
      display: block;
    }

    .card {
      margin: 0 auto;
      max-width: 700px;
    }

    .card-footer {
      button {
        margin-top: 20px;
      }
    }
  }
}
