.App {
  .comp-manual-capture-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    .captured-section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      .confirmation-section {
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
          margin: auto;
        }
      }
      .title {
        width: 90%;
        margin: auto;
        font-size: 1.2em;
      }
      .loader-container {
        display: flex;
        margin: auto;
        justify-content: center;
      }
      img {
        width: 80%;
        padding: 0.4rem 0;
      }
      .confirmaition-links {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        .button-big {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1em;
          width: auto;
          min-width: 6.9rem;
          margin: 0;
          svg {
            width: 20px;
            height: 20px;
            .a {
              stroke: $whiteColor;
            }
          }
        }
      }
      .loader-section {
        img {
          width: 85%;
        }
        div {
          height: 5vh;
        }
      }
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-manual-capture-message {
    .captured-section {
      img {
        width: 60%;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-manual-capture-message {
    .captured-section {
      .confirmaition-links {
        width: 60%;
      }
      img {
        width: auto;
        height: 60vh;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-manual-capture-message {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.App.desktop {
  .comp-manual-capture-message {
    position: relative;
    width: 45vw;
    height: calc(45vw / 1.6);
    margin: calc(5vh) auto;
    .captured-section {
      .confirmation-section {
        height: 90%;
      }
      img,
      .confirmaition-links {
        width: 90%;
      }
      img {
        padding: 1.25rem 0;
      }
    }
    .loader-section {
      img {
        width: 90%;
      }
      div {
        height: 0;
        margin-top: 3vh;
        span {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
}

@media (max-width: 374px) {
  .App.portrait-primary {
    .comp-manual-capture-message {
      .captured-section {
        .confirmation-section {
          height: 85vh;
        }
      }
    }
  }
}
