.App {
  .comp-qrcode-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    display: flex;
    .fail-pic {
      width: 100px;
      height: 63px;
      margin-bottom: 20px;
    }
    .frame {
      border-radius: 12px;
      margin: auto;
      position: relative;
      width: 100%;
      height: 100%;
      .qrcode-frame {
        width: 90%;
        height: 90%;
        position: absolute;
        left: 5%;
        top: 5%;
        overflow: visible;
        .a, .b, .c {
          fill: none;
        }
        .a, .b {
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 0 15;
          opacity: 1;
        }
        .a, .c {
          stroke: $whiteColor;
          stroke-width: 6px;
        }
        .b, .e {
          stroke: $blackColor;
          stroke-width: 4px;
        }
        .d {
          clip-path: none;
        }
      }
      .animatable {
        .b, .e {
          stroke: $scanFrameAnimationColor;
        }
        .c, .e {
          animation: circle 3s infinite linear;
        }
      }
      .success-frame {
        .b {
          stroke: $successCheckmarkColor;
        }
      }
      .spacer {
        padding: 8px;
        height: calc(100% - 16px);
        display: flex;
        align-items: center;
        justify-content: center;
        .comp-success-pictogram {
          position: relative;
        }
        .inner-frame {
          background-color: $qrCodeErrorFrameColor;
          border-radius: 5px;
          display: flex;
          width: 85%;
          height: 85%;
          .button {
            margin-top: 20px;
            width: 30%;
            display: block;
            min-width: 180px;
          }
          .text {
            color: $whiteColor;
            line-height: 25px;
            font-size: 1.2em;
          }
          div {
            margin: auto;
            width: 90%;
            max-width: 1300px;
            width: 100%;
            .fail-pic {
              width: 60px;
              height: 60px;
              margin-bottom: 5px;
              .a, .b, .c {
                fill: white;
              }
            }
            .text {
              margin: 10px 0 5px 0;
            }
            div {
              outline: none;
              opacity: $overlayOpacity;
            }
            .button {
              opacity: $buttonOpacity;
              position: relative;
            }
            .button-big {
              box-sizing: border-box;
            }
          }
          .button-link {
            color: $buttonTextColor;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-qrcode-message .frame {
    width: calc(96vh * 1.7777);
    height: calc(68vh + 0px);
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-qrcode-message {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90vh;
    height: 90vh;
    .frame {
      margin: auto;
      max-width: 100%;
      height: 100%;
      .e, .c {
        width: 99.5%;
      }
    }
  }
}

.App.desktop {
  .comp-qrcode-message {
    position: relative;
    width: 85vw;
    height: 85vw;
    max-width: 400px;
    max-height: 400px;
    margin: 7vh auto;
    .frame {
      min-width: 350px;
      svg {
        margin-top: 0px;
      }
    }
  }
}

.App.mobile {
  .comp-qrcode-message {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .frame {
      .e, .c {
        width: 98%;
      }
    }
  }
}

/* Larger than mobile */
@media (min-width: 400px) {
  .App.landscape-primary.mobile, .App.landscape-secondary.mobile {
    .comp-qrcode-message .frame .spacer .inner-frame div .text {
      margin-top: 0;
    }
  }
}
