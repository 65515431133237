.App {
  div[role="button"]:active,
  div[role="button"]:focus {
    outline: 0;
  }
  a {
    color: $primaryLinkColor;
  }
  .button,
  .button-big {
    cursor: pointer;
  }
  .button-big {
    background: $buttonBackgroundColor;
    border: $buttonBorder;
    box-shadow: $buttonBoxShadow;
    border-radius: $buttonBorderRadius;
    text-align: center;
    font-size: 1.3em;
    color: $buttonTextColor;
    display: inline-block;
    width: 60%;
    margin: auto;
    padding: 6px;
  }
  .button-big.inactive {
    background: $inactiveButtonBackgroundColor;
    border: $inactiveButtonBorder;
    color: $inactiveButtonTextColor;
  }
  div.table {
    display: table;
  }
  div.table-row {
    display: table-row;
  }
  div.table-cell {
    display: table-cell;
  }
  .capitalized {
    text-transform: capitalize;
  }
  .back-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 24px;

    .icon-back {
      width: 8px;
      height: 14px;
      margin-right: 14px;
    }
  }
  section {
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    button.button-link {
      background: $primaryBackgroundColor;
      border: 0;
      text-decoration: underline;
      font-size: 1.1em;
    }
  }
  .no-internet-connection {
    overflow: hidden;
  }
  .error {
    color: $errorTextColor;
  }
  .loader-overlay {
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;

    span {
      width: 3rem;
      height: 3rem;
    }
  }
  .button-with-loader {
    .loader {
      margin: 0;
      right: -35px;
      pointer-events: none;
    }
    .button-big {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 1em auto;
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  section {
    .button-big {
      font-size: 1.3em;
      padding: 5px;
    }
  }
}

.App.desktop {
  height: 60vh;
  section {
    .card {
      width: 50vw;
      min-width: 500px;
    }
    .card {
      height: auto;
      min-height: 60vh;
      border-radius: 12px;
      box-shadow: 0 16px 45px #00000015;
      display: block;
      margin: auto;
      padding: 20px 20px 30px;
    }
  }
}
