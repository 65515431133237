.App {
  .comp-rating-item {
    white-space: nowrap;
    .star {
      width: 34px;
      height: 34px;
      margin-right: 16px;
    }
  }
}

//iPhone 5
@media (max-width: 374px) {
  .App {
    .comp-rating-item {
      .star {
        width: 30px;
        margin-right: 6px;
      }
    }
  }
}

//strange devices with weird resolution
@media (max-width: 280px) {
  .App {
    .comp-rating-item {
      .star {
        width: 25px;
        margin-right: 4px;
      }
    }
  }
}
