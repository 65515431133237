.App {
  .comp-select-type {
    h1 {
      width: 90%;
    }
    .doc-types:last-child {
      border-bottom: 2px solid #bbb;
    }
    .clicable-area {
      min-height: 14vh;
      display: flex;
      align-items: center;
      border-top: 2px solid #bbb;
      cursor: pointer;
      .clicable-area-item {
        width: 70%;
        padding: 0 10px;
        .doc-type-icon {
          .a {
            fill: $black2Color;
            stroke-width: 0;
          }
        }
        .id-pic {
          .a {
            fill: none;
          }
        }
        .passport-pic {
          .b {
            opacity: 0.6;
          }
        }
        .forward-icon {
          .a {
            stroke: $forwardArrow;
          }
        }
      }
      .clicable-area-item:first-child {
        width: 30%;
      }
      .clicable-area-item:last-child {
        width: 10%;
      }
      .text-caption {
        text-align: left;
      }
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-select-type {
    .text-caption {
      span {
        display: block;
      }
    }
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-select-type {
    h1 {
      font-size: 1.5em;
    }
    .doc-types {
      font-size: medium;
      .doc-type-icon {
        width: 45px;
        height: 45px;
      }
      .id-pic {
        width: 50px;
        height: 50px;
      }
      .passport-pic {
        width: 38px;
        height: 40px;
      }
    }
  }
}

.App.desktop {
  .comp-select-type {
    .card {
      padding: 20px 0 30px;
    }
  }
}
