.App {
  .comp-dv {
    display: flex;
    flex: 1;
    .card {
      height: 100%;
    }

    .tech-message,
    .message {
      color: white;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      text-align: right;
      span {
        background: #070707;
      }
    }

    .tech-message {
      font-size: 0.9em;
      font-family: monospace;
      top: 0;
      right: 0;
      span {
        background: #9c4444; //no need to var color
      }
    }

    .video-container {
      height: 100vh;
      width: 100vw;
      display: block;
      position: relative;
      overflow: hidden;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: $videoBackgroundColor;
    }

    .video-zoom video {
      width: 200vw;
      height: 200vh;
      left: -50vw;
      top: -50vh;
    }

    video.mirrored {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg); /* Safari and Chrome */
      -moz-transform: rotateY(180deg); /* Firefox */
    }

    canvas {
      width: 100%;
      height: 100%;
      display: none;
      .flip {
        transform: scaleX(-1);
        filter: FlipH;
      }
    }

    .video-canvas-size {
      height: 85%;
    }

    div.take {
      position: absolute;
      right: calc(50vw - 40px);
      bottom: 10px;
    }

    .overlay-button {
      background: $overlayButtonBackgroundColor;
      color: $overlayButtonTextColor;
    }
  }
}

.App.portrait-primary,
.App.portrait-secondary {
  .comp-dv {
    height: 100%;
    width: 100%;
    position:fixed;
    overflow: hidden;
    top:0;
    left:0;
    right:0;
    bottom:0;
    flex: 1;
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.App.landscape-primary.mobile,
.App.landscape-secondary.mobile {
  .comp-dv {
    height: 100%;
    width: 100vw;
    position:fixed;
    overflow: hidden;
    top:0;
    left:0;
    right:0;
    bottom:0;
    .video-canvas-size {
      width: 85vw;
      height: 100%;
    }
  }
}

.App.desktop {
  .comp-dv {
    height: auto;
    flex-direction: column;
    .card {
      padding: 0;
      position: relative;
    }
    video {
      border-radius: 12px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
    }
  }
}

.App.mobile {
  .comp-dv {
    header {
      display: none;
    }
  }
}
