.App {
  .information-for-mtan-page {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-bottom: 50px;
    section {
      height: 100%;
      width: 100%;
      text-align: center;
      display: block;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .card-header h1 {
        font-weight: normal;
        font-size: 2em;
        margin: 20px 0 10px;
      }
      .card-body {
        flex: 1 0 auto;
      }
      .card-footer {
        button {
          margin-top: 20px;
        }
      }
    }
  }
}
.App.desktop section .information-for-mtan-page .card {
  display: flex;
}
