.App {
  section {
    .comp-contract {
      &-sign-page {
        &.card {
          display: flex;
          flex-direction: column;
          .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            flex-grow: 1;
            .content-text {
              font-size: 20px;
              line-height: 1.5em;
            }
          }
        }
        .button-with-loader {
          margin-top: 50px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          .button-big {
            width: 65%;
            &.inactive {
              background: $inactiveButtonBackgroundColor;
              border: $inactiveButtonBorder;
              color: $inactiveButtonTextColor;
            }
          }
        }
      }
    }
  }
}
